<template>
  <div class="site-wrapper">
    <div class="a-main a-main--page">
      <div class="a-main__head bg-linear">
        <div class="a-curve">
          <svg
            class="a-curve-svg fill-white"
            viewBox="0 0 366 48"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M374 0C212.8 76.8 57.5 32 0 0V47.5H374V0Z" />
          </svg>
        </div>
        <div class="container">
          <div class="a-head">
            <div class="a-head__logo-wrap d-flex justify-content-between">
              <div class="a-head__back align-self-center">
                <router-link :to="{ path: '/home' }">
                  <BackArrowSvg />
                </router-link>
              </div>
              <div class="a-head__page d-flex align-self-center">
                <span class="a-head__num align-self-center">01</span>
                <span class="a-head__count align-self-center">/ 03</span>
              </div>
            </div>
            <div class="a-head__content text-center">
              <div class="a-head__icn">
                <StarSvg :viewBox="'0 0 36 36'" />
              </div>
              <h2 class="page-title">Sleep Apnea</h2>
              <p class="page-par">3 Sleep Apnea Events in the past 7 Days</p>
              <div class="a-head__icn">
                <SleepApneaSvg
                  :width="36"
                  :height="31"
                  :viewBox="'0 0 36 31'"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="a-main__body">
        <div class="container">
          <div class="a-main__wrap">
            <div class="a-data">
              <div class="a-data__list d-flex justify-content-between">
                <div class="a-data__title align-self-center">
                  Pharmacist will call you
                </div>
                <div class="a-data__info align-self-center">
                  <svg
                    class="a-tick"
                    viewBox="0 0 14 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 4.83158L5.16842 9L12.4526 1"
                      stroke="#10C1BC"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
              <div class="a-data__list d-flex justify-content-between">
                <div class="a-data__title align-self-center">
                  Schedule a time to meet
                </div>
                <div class="a-data__info align-self-center">
                  <svg
                    class="a-tick"
                    viewBox="0 0 14 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 4.83158L5.16842 9L12.4526 1"
                      stroke="#10C1BC"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
              <div class="a-data__btn btn btn-green">
                <a href="tel:1234" class="btn-link"> Call Now </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="a-main__footer">
        <div class="container">
          <div class="a-main__footer-wrap text-center">
            <div class="a-main__footer-logo">
              <img src="images/amcal-logo.png" />
            </div>
            <p class="sub-par">Your pharmacy is Lowe’s Amcal, Werribee</p>
            <div class="a-main__footer-option">
              <router-link :to="{ path: 'account' }">
                Change Pharmacy
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SleepApneaSvg from "@/components/svg/SleepApneaSvg";
import StarSvg from "@/components/svg/StarSvg";
import BackArrowSvg from "@/components/svg/BackArrowSvg";
export default {
  components: {
    SleepApneaSvg,
    BackArrowSvg,
    StarSvg,
  },
};
</script>